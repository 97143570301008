/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, {
	getCurrentInstance,
	// reactive
} from '@vue/composition-api'
Vue.use(VueCompositionApi)
import dateformat from 'dateformat'
import numeral from 'numeral'

const getPaymentAbbreviation = (item) => {
	switch (item) {
		case 'collect bank':
			return 'BT'
		case 'collect bank in advance':
			return 'BT'
		case 'collect bank reminder fee':
			return 'BT'
		case 'collect cash':
			return 'CT'
		case 'collect cash in advance':
			return 'CT'
		case 'collect cash reminder fee':
			return 'CT'
		case 'setoff':
			return 'SO'
		case 'set off credit invoice':
			return 'SO'
		case 'set off advanced payment':
			return 'SO'
		default:
			return '??'
	}
}
const getTransactionType = (payload) => {
	console.log('payload', payload)
	switch (payload) {
		case 'BT':
			return 'till bankkonto'
		case 'CT':
			return 'kontant'
		case 'SO':
			return 'Övriga'
		default:
			return '??'
	}
}
// Create uuid
const vm = getCurrentInstance()
const NAMESPACE = '96e1b132-5765-5e15-b9a8-8a6cec456dda'

function getUUID() {
	let TIMEPARAMETER = (
		Date.now() + Math.floor(Math.random() * 1000000000000000000).toString()
	).toString()
	return vm.$uuid.v5(TIMEPARAMETER, NAMESPACE)
}
// Get date and format it
const getNewDateWithTime = () => {
	let documentDateFromSystem = new Date()
	return dateformat(documentDateFromSystem, 'yyyy-mm-dd HH:MM')
}
// format numbers
// format number from database to table with two decimal
const numberFromDataBaseToTable = (number) => {
	const correctedNumber = number / 100
	return numeral(correctedNumber).format('0,0.00')
}
const roundedReportNumber = (numberToReport) => {
	return Math.round(numberToReport / 100)
}
const addCurrency = (number, currency = 'kr.') => {
	return numeral(number).format() + ' ' + currency
}
const roundedCurrencyAddedNumber = (number, currency) => {
	return addCurrency(roundedReportNumber(number), currency)
}
// todo ---------------------------------------------------------------
const addInvoiceFeeToPayment = (payload) => {
	let feeToPay = 0
	if (payload.invoice_type === 'Inland') {
		feeToPay = payload.is_fee_addable
			? payload.paper_invoice_fee + payload.paper_invoice_fee_vat
			: 0
	}
	return feeToPay
}
// ------------------- Invoice related functions ---------------------
const getSalesInvoiceTotal = (payload) => {
	return roundedReportNumber(
		payload.invoice_totals_aggregate.aggregate.sum.line_price_total_credit +
			payload.invoice_totals_aggregate.aggregate.sum.vat_credit +
			addInvoiceFeeToPayment(payload),
	)
}
const getPurchaseInvoiceTotal = (payload) => {
	return roundedReportNumber(
		payload.invoice_totals_aggregate.aggregate.sum.line_price_total_debit +
			payload.invoice_totals_aggregate.aggregate.sum.vat_debit,
	)
}
const invoiceToPay = (payload) => {
	const salesInvoiceToDb = getSalesInvoiceTotal(payload) * 100
	const purchaseInvoiceToDb = getPurchaseInvoiceTotal(payload) * 100
	let correctedToPay
	if (payload.transaction_type === 'sales invoice ') {
		correctedToPay = addCurrency(getSalesInvoiceTotal(payload))
	} else {
		correctedToPay = addCurrency(getPurchaseInvoiceTotal(payload))
	}
	return { correctedToPay, salesInvoiceToDb, purchaseInvoiceToDb }
}
const invoicePayment = (payload) => {
	const salesInvoicePayment =
		payload.invoice_payments_aggregate.aggregate.sum.payment_credit || 0
	const purchaseInvoicePayment =
		payload.invoice_payments_aggregate.aggregate.sum.payment_debit || 0
	let correctedPayment
	if (payload.transaction_type === 'sales invoice ') {
		correctedPayment = roundedCurrencyAddedNumber(salesInvoicePayment)
	} else {
		correctedPayment = roundedCurrencyAddedNumber(purchaseInvoicePayment)
	}
	return {
		correctedPayment,
		salesInvoicePaymentToDb: salesInvoicePayment,
		purchaseInvoicePaymentToDb: purchaseInvoicePayment,
	}
}
const invoiceBalance = (payload) => {
	const toPay =
		payload.invoice_totals_aggregate.aggregate.sum.line_price_total_credit +
		payload.invoice_totals_aggregate.aggregate.sum.vat_credit +
		addInvoiceFeeToPayment(payload)
	const payment =
		payload.invoice_payments_aggregate.aggregate.sum.payment_credit || 0
	const saleInvoiceBalance = toPay - payment
	const correctedBalance = roundedCurrencyAddedNumber(saleInvoiceBalance)
	const invoiceBalanceForReminder = Math.round(saleInvoiceBalance / 100) * 100
	return {
		correctedBalance,
		saleInvoiceBalanceToDb: saleInvoiceBalance,
		invoiceBalanceForReminder,
	}
}
// ---????----
const invoiceBalance2 = (payload) => {
	const saleInvoiceBalance =
		getSalesInvoiceTotal(payload) * 100 -
		payload.invoice_payments_aggregate.aggregate.sum.payment_credit
	const purchaseInvoiceBalance =
		getPurchaseInvoiceTotal(payload) * 100 -
		payload.invoice_payments_aggregate.aggregate.sum.payment_debit
	let correctedBalance
	if (payload.transaction_type === 'sales invoice ') {
		correctedBalance = addCurrency(getSalesInvoiceTotal(payload))
	} else {
		correctedBalance = addCurrency(getPurchaseInvoiceTotal(payload))
	}
	return {
		correctedBalance,
		saleInvoiceBalanceToDb: saleInvoiceBalance,
		purchaseInvoiceBalanceToDb: purchaseInvoiceBalance,
	}
}
// debounce function set delay for another click
const debounce = (fn, delay) => {
	let id
	console.log(`id at immediate load ${id}`)
	return (...args) => {
		console.log(`previous id ${id}`)
		if (id) clearTimeout(id)
		id = setTimeout(() => {
			fn(...args)
		}, delay)
	}
}
export default function storeAccounting() {
	return {
		getPaymentAbbreviation,
		getUUID,
		getNewDateWithTime,
		numberFromDataBaseToTable,
		roundedCurrencyAddedNumber,
		getTransactionType,
		invoiceToPay,
		invoicePayment,
		invoiceBalance,
		invoiceBalance2,
		debounce,
	}
}
