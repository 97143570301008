var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('layout-wide',[_c('sub-navbar',{attrs:{"page":"Reminder"}}),_c('div',[(_vm.loading)?_c('div',[_vm._v("...Loading Please wait!!!")]):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"control"},[_c('b-taglist',{attrs:{"attached":""}},[_c('b-tag',{staticClass:"is-medium",attrs:{"type":"is-info"}},[_vm._v(" Unpaid Customer's Reminded Invoices Process ")]),_c('b-tag',{staticClass:"is-medium",attrs:{"type":"is-dark"}},[_vm._v(" Send to Inkasso ")])],1)],1),_c('div',[_c('b-button',{staticClass:"is-success margin-bottom-20 margin-top-20",on:{"click":_vm.refreshData}},[_vm._v(" Refresh the data ")]),(_vm.showWhich === 'notSent')?_c('b-button',{staticClass:"is-danger margin-bottom-20 margin-top-20 margin-left-20",on:{"click":function($event){return _vm.getSentInTheBailiffs()}}},[_vm._v(" Get the invoices sent to inkasso ")]):_vm._e(),(_vm.showWhich === 'sent')?_c('b-button',{staticClass:"is-info margin-bottom-20 margin-top-20 margin-left-20",on:{"click":function($event){return _vm.getUnpaidRemindedInvoices()}}},[_vm._v(" Get the unpaid reminded invoices not sent to inkasso ")]):_vm._e()],1),_c('div',{staticClass:"margin-bottom-20"},[_c('span',{staticClass:"text-bolder"},[_vm._v(_vm._s(_vm.tag))]),_c('span',{staticClass:"text-bolder text-red"},[_vm._v(" "+_vm._s(_vm.roundedCurrencyAddedNumber(_vm.totalBalance))+" ")])]),_c('div',[_c('vue-good-table',{key:_vm.keyTable,attrs:{"id":"selected_dispatches","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","fixed-header":true,"search-options":{
							enabled: true,
						},"pagination-options":{
							enabled: true,
							mode: 'records',
							perPage: 100,
							position: 'bottom',
							perPageDropdown: [5, 10, 15, 20, 100],
							dropdownAllowAll: false,
							setCurrentPage: 1,
							jumpFirstOrLast: true,
							firstLabel: 'First',
							lastLabel: 'Last',
							nextLabel: 'next',
							prevLabel: 'prev',
							rowsPerPageLabel: 'Rows per page',
							ofLabel: 'of',
							pageLabel: 'page', // for 'pages' mode
							allLabel: 'All',
						}},on:{"on-row-dblclick":_vm.onRowDoubleClick,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'inkasso')?_c('span',[_c('b-button',{staticClass:"is-small is-info"},[_vm._v(_vm._s(_vm.doWhat))])],1):(props.column.field === 'reminderDueDate')?_c('span',[_c('span',{staticClass:"text-bold text-purple"},[_vm._v(" "+_vm._s(props.row.reminderDueDate)+" ")])]):(props.column.field === 'invoiceBalance')?_c('span',[_c('p',{staticClass:"text-bold text-red text-align-right"},[_vm._v(" "+_vm._s(props.row.invoiceBalance)+" ")])]):(props.column.field === 'invoiceNumber')?_c('span',[_c('p',{staticClass:"text-bold has-text-link text-align-right"},[_c('a',{staticStyle:{"text-decoration-line":"underline"},attrs:{"href":"url"}},[_vm._v(" "+_vm._s(props.row.invoiceNumber)+" ")])])]):(props.column.field === 'reminderId')?_c('span',[_c('p',{staticClass:"text-bold has-text-link text-align-right"},[_c('a',{staticStyle:{"text-decoration-line":"underline"},attrs:{"href":"url"}},[_vm._v(" "+_vm._s(props.row.reminderId)+" ")])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,131381819)})],1),_c('download-csv',{attrs:{"data":_vm.rows}},[_c('div',{staticClass:"margin-bottom-30 margin-top-30"},[_c('b-button',{staticClass:"is-success"},[_vm._v(" Download the List ")])],1)])],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }