<!-- @format -->

<template>
	<div>
		<layout-wide>
			<sub-navbar page="Reminder" />
			<div>
				<div v-if="loading">...Loading Please wait!!!</div>
				<div v-if="!loading">
					<div class="control">
						<b-taglist attached>
							<b-tag type="is-info" class="is-medium">
								Unpaid Customer's Reminded Invoices Process
							</b-tag>
							<b-tag type="is-dark" class="is-medium"> Send to Inkasso </b-tag>
						</b-taglist>
					</div>
					<div>
						<b-button
							class="is-success margin-bottom-20 margin-top-20"
							@click="refreshData"
						>
							Refresh the data
						</b-button>
						<b-button
							v-if="showWhich === 'notSent'"
							class="is-danger margin-bottom-20 margin-top-20 margin-left-20"
							@click="getSentInTheBailiffs()"
						>
							Get the invoices sent to inkasso
						</b-button>
						<b-button
							v-if="showWhich === 'sent'"
							class="is-info margin-bottom-20 margin-top-20 margin-left-20"
							@click="getUnpaidRemindedInvoices()"
						>
							Get the unpaid reminded invoices not sent to inkasso
						</b-button>
					</div>
					<div class="margin-bottom-20">
						<span class="text-bolder">{{ tag }}</span>
						<span class="text-bolder text-red">
							{{ roundedCurrencyAddedNumber(totalBalance) }}
						</span>
					</div>
					<div>
						<vue-good-table
							:key="keyTable"
							id="selected_dispatches"
							:columns="columns"
							:rows="rows"
							styleClass="vgt-table striped bordered"
							theme="black-rhino"
							:fixed-header="true"
							@on-row-dblclick="onRowDoubleClick"
							@on-cell-click="onCellClick"
							:search-options="{
								enabled: true,
							}"
							:pagination-options="{
								enabled: true,
								mode: 'records',
								perPage: 100,
								position: 'bottom',
								perPageDropdown: [5, 10, 15, 20, 100],
								dropdownAllowAll: false,
								setCurrentPage: 1,
								jumpFirstOrLast: true,
								firstLabel: 'First',
								lastLabel: 'Last',
								nextLabel: 'next',
								prevLabel: 'prev',
								rowsPerPageLabel: 'Rows per page',
								ofLabel: 'of',
								pageLabel: 'page', // for 'pages' mode
								allLabel: 'All',
							}"
						>
							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field === 'inkasso'">
									<b-button class="is-small is-info">{{ doWhat }}</b-button>
								</span>
								<span v-else-if="props.column.field === 'reminderDueDate'">
									<span class="text-bold text-purple">
										{{ props.row.reminderDueDate }}
									</span>
								</span>
								<span v-else-if="props.column.field === 'invoiceBalance'">
									<p class="text-bold text-red text-align-right">
										{{ props.row.invoiceBalance }}
									</p>
								</span>
								<span v-else-if="props.column.field === 'invoiceNumber'">
									<p class="text-bold has-text-link text-align-right">
										<a href="url" style="text-decoration-line: underline">
											{{ props.row.invoiceNumber }}
										</a>
									</p>
								</span>
								<span v-else-if="props.column.field === 'reminderId'">
									<p class="text-bold has-text-link text-align-right">
										<a href="url" style="text-decoration-line: underline">
											{{ props.row.reminderId }}
										</a>
									</p>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</vue-good-table>
					</div>
					<download-csv :data="rows">
						<div class="margin-bottom-30 margin-top-30">
							<b-button class="is-success"> Download the List </b-button>
						</div>
					</download-csv>
				</div>
			</div>
		</layout-wide>
	</div>
</template>

<script>
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import Store from '@/store'
import GetUnpaidInvoicesByOptionsQuery from '@/_srcv2/pages/reminder/graphql/GetUnpaidInvoicesByOptionsQuery.graphql'
import SetInvoiceIsSentInTheBailiffsMutation from '@/_srcv2/pages/reminder/graphql/SetInvoiceIsSentInTheBailiffsMutation.gql'
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import storeAccounting from '@/_srcv2/pages/_pages-store/storeShared.js'
import moment from 'moment'

export default {
	name: 'SendToInkasso',
	components: {
		SubNavbar,
	},
	setup() {
		const doWhat = ref('Send to ink.')
		const keyTable = ref(1)
		onMounted(() => {
			setVariablesForTable('false', true, false)
			showWhich.value = 'notSent'
			keyTable.value = keyTable.value + 1
		})
		// Get Data from database
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const variablesForTable = reactive({
			is_invoice_paid: 'false',
			our_company: Store.getters.getUserCurrentCompany,
			is_invoice_reminded: true,
			is_sent_in_the_bailiffs: false,
		})
		const setVariablesForTable = (isPaid, isReminded, isSent) => {
			return new Promise((resolve, reject) => {
				if (variablesForTable.our_company !== null || undefined) {
					variablesForTable.is_invoice_paid = isPaid
					variablesForTable.is_invoice_reminded = isReminded
					variablesForTable.is_sent_in_the_bailiffs = isSent
					resolve(result)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const { result, loading, onResult, refetch } = useQuery(
			GetUnpaidInvoicesByOptionsQuery,
			() => variablesForTable,
			options,
		)
		const searchedInvoices = useResult(
			result,
			null,
			(data) => data.document_transactions,
		)
		// -----------------------------------------------------------------------------
		const getSentInTheBailiffs = () => {
			setVariablesForTable('false', true, true).then(() => refetch())
			showWhich.value = 'sent'
			doWhat.value = 'Draw back'
			keyTable.value = keyTable.value + 1
			tag.value = `Total Balance of invoices sent to inkasso: `
		}
		const getUnpaidRemindedInvoices = () => {
			setVariablesForTable('false', true, false).then(() => refetch())
			doWhat.value = 'Send to ink.'
			showWhich.value = 'notSent'
			keyTable.value = keyTable.value + 1
			tag.value = `Total Balance of unpaid reminded invoices: `
		}
		// Create the table columns
		const columns = reactive([
			{
				label: '*',
				field: 'isItOverdue',
				width: '15px',
			},
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '115px',
			},
			{
				label: 'Customer',
				field: 'customer',
			},
			// {
			// 	label: 'Customer Nickname',
			// 	field: 'customerNickname',
			// },
			{
				label: 'Invoice due date',
				field: 'invoiceDueDate',
				width: '115px',
			},
			{
				label: 'Reminder Number',
				field: 'reminderId',
				width: '115px',
			},
			{
				label: 'Reminder due date',
				field: 'reminderDueDate',
				width: '125px',
				html: true,
			},
			{
				label: 'Invoice Balance',
				field: 'invoiceBalance',
				width: '125px',
				html: true,
			},
			{
				label: 'Inkasso',
				field: 'inkasso',
				width: '50px',
				sortable: false,
			},
		])
		// Create the table rows
		const getDueDate = () => {
			let date = new Date()
			let daysToSubtract
			switch (moment(date).day()) {
				// Sunday = 4 days
				case 0:
					daysToSubtract = 4
					break
				// Monday and Tuesday = 5 days
				case 1:
				case 2:
					daysToSubtract = 5
					break
				// Subtract 2 days otherwise.
				default:
					daysToSubtract = 3
					break
			}
			return moment(date).subtract(daysToSubtract, 'days').format('YYYY-MM-DD')
		}
		const getStarForOverdueInvoices = (reminderDueDate) => {
			console.log('dueDate', getDueDate())
			console.log('reminderDueDate', reminderDueDate)
			if (reminderDueDate <= getDueDate()) {
				return '*'
			} else return ''
		}
		const {
			// invoiceToPay,
			// invoicePayment,
			invoiceBalance,
			roundedCurrencyAddedNumber,
		} = storeAccounting()
		const rows = computed(() =>
			searchedInvoices.value
				.map((row) => {
					return {
						customer:
							row.customer.customer_id +
							' - ' +
							row.customer.customer_title +
							' - ' +
							row.customer.customer_nickname,
						// customerTitle: row.customer.customer_title,
						// customerNickname: row.customer.customer_nickname,
						invoiceNumber: row.invoice_number,
						invoiceDate: row.invoice_date,
						invoiceDueDate: row.invoice_due_date,
						// isInvoicePaid: row.is_invoice_paid,
						// isInvoiceReminded: row.is_invoice_reminded,
						// isSentInTheBailiffs: row.is_sent_in_the_bailiffs,
						reminderId: row?.reminder_lines?.reminder_id || '',
						reminderDate:
							row?.reminder_lines?.reminder_header?.reminder_date || '',
						reminderDueDate:
							row?.reminder_lines?.reminder_header?.reminder_due_date || '',
						// invoiceToPay: invoiceToPay(row),
						// invoicePayment: invoicePayment(row),
						invoiceBalance: invoiceBalance(row).correctedBalance,
						// saleInvoiceBalanceToDb:
						// 	invoiceBalance(row).saleInvoiceBalanceToDb,
						isItOverdue: getStarForOverdueInvoices(
							row.reminder_lines.reminder_header.reminder_due_date,
						),
					}
				})
				.sort(function (a, b) {
					const reminderDueDateA = a.reminderDueDate
					const reminderDueDateB = b.reminderDueDate
					if (reminderDueDateA < reminderDueDateB) {
						return -1
					}
					if (reminderDueDateA > reminderDueDateB) {
						return 1
					}
					// dates must be equal
					return 0
				}),
		)
		// Create the table functions
		const onCellClick = (params) => {
			if (params.column.field === 'inkasso') {
				switch (doWhat.value) {
					case 'Send to ink.':
						getVariables(params.row.invoiceNumber)
							.then(() => {
								setInBailiff()
							})
							.then(() => refetch())
						break
					case 'Draw back':
						console.log('Draw back is fired')
						getVariables(params.row.invoiceNumber)
							.then(() => {
								setInBailiff()
							})
							.then(() => refetch())
						break
					default:
						return
				}
				return params.row
			}
		}
		const onRowDoubleClick = (params) => {
			return params.row
		}
		// Get total balance
		const tag = ref(`Total Balance of unpaid reminded invoices: `)
		const totalBalance = ref(0)
		onResult(() => {
			console.log(' new result is fetched')
			console.log('rows', rows.value)
			const arr = rows.value.map((item) => item.saleInvoiceBalanceToDb)
			console.log('arr', arr)
			totalBalance.value = arr.reduce((a, b) => a + b, 0)
		})
		const refreshData = () => {
			refetch().then(() => window.alert(' The data is refreshed'))
		}
		// -------------------------------------------------------------------
		const mutationVariablesBailiffs = reactive({
			invoice_number: '',
			our_company: '',
			is_sent_in_the_bailiffs: true,
		})
		const { mutate: setInBailiff, onDone: onDoneSetInBailiff } = useMutation(
			SetInvoiceIsSentInTheBailiffsMutation,
			() => ({
				variables: mutationVariablesBailiffs,
			}),
		)
		onDoneSetInBailiff((result) => {
			console.log('onDone:', result.data)
			const message = ref('')
			if (doWhat.value === 'Send to ink.') {
				message.value = 'is sent to inkasso'
			} else {
				message.value = 'is drawn back'
			}
			window.alert(`
      ${result.data.update_document_transactions.returning[0].invoice_number} ${message.value}
      `)
		})

		const getVariables = (invoiceNumber) => {
			return new Promise((resolve, reject) => {
				if (invoiceNumber !== null || undefined) {
					mutationVariablesBailiffs.invoice_number = invoiceNumber
					mutationVariablesBailiffs.our_company =
						Store.getters.getUserCurrentCompany
					if (doWhat.value === 'Send to ink.') {
						mutationVariablesBailiffs.is_sent_in_the_bailiffs = true
					} else if (doWhat.value === 'Draw back') {
						mutationVariablesBailiffs.is_sent_in_the_bailiffs = false
					}
					resolve(mutationVariablesBailiffs)
				} else {
					const reason = new Error(
						'Invoice Number could not be fetched from database',
					)
					reject(reason)
				}
			})
		}
		// todo --------------------------------------------------------

		const showWhich = ref('notSent')
		return {
			loading,
			tag,
			roundedCurrencyAddedNumber,
			totalBalance,
			searchedInvoices,
			refreshData,
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			getSentInTheBailiffs,
			getUnpaidRemindedInvoices,
			showWhich,
			keyTable,
			doWhat,
		}
	},
}
</script>

<style scoped></style>
